import { Grid, styled, Typography } from '@mui/material'
import React from 'react'
import { Icon } from '@iconify/react'

interface Props {
  title:string
  description: string
  icon:any
}

const StyledIcon = styled(Icon)`
  position: absolute;
  bottom: 0;
  left: 0px;
`


const ValueCard = ({title, description, icon}:Props) => {
  return (
    <Grid
      data-aos="fade-up"
      data-aos-duration="1000"
      flexDirection="column"
      justifyContent="start"
      flexGrow="1"
      maxWidth={['100%', '30%', '30%']}
      px={3}
      py={5}
      pt={7}
      m={2}
      position="relative"
      sx={{ bgcolor: "custom.main" }}
    >
      <StyledIcon icon={icon} height="140px" color="#FA9E12" />
      <Typography
        position="relative"
        zIndex="2"
        textAlign="center"
        variant="h3"
        mb={3}
        sx={{ color: "custom.dark" }}
      >
        {title}
      </Typography>
      <Typography position="relative" zIndex="2" textAlign="center" variant="body1" sx={{ color: "custom.dark" }}>
        {description}
      </Typography>
    </Grid>
  )
}

export default ValueCard