import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";
import BgImg from "../../../images/home-development.png";

interface Props {
  TitleSection: string;
  body1: string;
  body2: string;
  title: string;
  body3: string;
  title1: string;
  body4: string;
  body5: string;
  body6: string;
  body7: string;
}

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.dark,
  borderBottom: `1px solid ${theme.palette.custom.dark}`,
  fontSize: "20px",
  paddingBottom: "8px",
  marginBottom: "24px",
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontWeight: "500",
}));

const Image = styled("img")(({ theme }) => ({
  overflow: "hidden",
  height: "350px",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },
}));

const CareerOpportunities = ({
  TitleSection,
  body1,
  body2,
  title,
  body3,
  title1,
  body4,
  body5,
  body6,
  body7,
}: Props) => {
  return (
    <Box sx={{ bgcolor: "custom.main" }} py={4}>
      <Box p={3}>
        <SectionTitle>{TitleSection}</SectionTitle>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              position="relative"
              right={[0, 0, 0, 4]}
              width={["100%", "100%", "100%", "70vw"]}
              flexGrow="1"
            >
              <Image src={BgImg} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              data-aos="fade-down"
              position="relative"
              px={4}
              py={6}
              left={[0, 0, 0, 95]}
              top={[0, 0, 0, 35]}
              zIndex="2"
              maxWidth={["100%", "100%", "100%", "600px"]}
              sx={{ bgcolor: "rgb(250, 158, 18)" }}
            >
              <Typography variant="h3" sx={{ color: "custom.dark" }} pb={3}>
                {body1}
              </Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>
                {body2}
              </Typography>
              <Typography
                variant="h3"
                color="black"
                sx={{ color: "custom.dark" }}
                pb={3}
              >
                {title}
                <Typography
                  variant="body1"
                  sx={{ color: "custom.dark" }}
                  pb={3}
                >
                  {body3}
                </Typography>
              </Typography>
              <Typography
                variant="h3"
                color="black"
                sx={{ color: "custom.dark" }}
              >
                {title1}
                <Typography
                  variant="body1"
                  sx={{ color: "custom.dark" }}
                  pb={3}
                >
                  {body4}
                </Typography>
              </Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>
                {body5}
              </Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>
                {body6}
              </Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>
                {body7}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CareerOpportunities;
