import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import crosshairIcon from "@iconify/icons-uil/crosshair";
import eyeIcon from "@iconify/icons-uil/eye";
import Card from "./Card";

interface Props {
  TitleSection: string;
  titleM: string;
  mision: string;
  mision2: string;
  titleV: string;
  vision: string;
}

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.main,
  borderBottom: `1px solid ${theme.palette.custom.main}`,
  fontSize: "20px",
  paddingBottom: "8px",
  marginBottom: "24px",
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontWeight: "500",
}));

const WhoWeAre = ({
  TitleSection,
  titleM,
  titleV,
  mision,
  mision2,
  vision,
}: Props) => {
  return (
    <Box pt={4} pb={[4, 10]} id="about" sx={{ bgcolor: "background.paper" }}>
      <Box p={3}>
        <SectionTitle>{TitleSection}</SectionTitle>
        <Grid container maxWidth="960px" mx="auto" flexDirection={["column", "row"]}>
          <Card
            dataAos="fade-right"
            icon={crosshairIcon}
            title={titleM}
            description1={mision}
            description2={mision2}
            bgColor="rgb(255,82,00)"
            color="white"
            iconColor="white"
            pr={["auto", 7]}
            left={["24px", "auto"]}
          />
          <Card
            dataAos="fade-left"
            icon={eyeIcon}
            title={titleV}
            bgColor="rgb(250,158,18)"
            color="white"
            iconColor="white"
            top={["-24px", 49]}
            left={["-24px", "-32px"]}
            description1={vision}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default WhoWeAre;
