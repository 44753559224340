
import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { Icon } from '@iconify/react';


interface Props {
  icon: any
  title: string
  description: string
}

const StyledFlex = styled(Box)(({theme}) => ({
  transition: 'all 0.2s ease-in',
  backgroundColor: 'rgb(250,158,18)',
  color: theme.palette.custom.main,
  textAlign: 'center',
  verticalAlign: 'top',
  '&:nth-child(odd)': {
    backgroundColor: theme.palette.primary.main
  },
  '& .cardDescription': {
    height: '0px',
    visibility: 'hidden',
  },
  '&:hover': {
    backgroundColor: 'rgb(255,82,00)',
    '& .cardDescription': {
      height: 'initial',
      visibility: 'visible',
    },
  }



}));

const HoverCard = ({icon, title, description}:Props) => {
  return (
    <StyledFlex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="440px"
      width={['100%', '100%', '33.33%']}
      flexGrow="1"
      p={5}
      display="inline-flex"
    >
      <Icon icon={icon} height="48px" />
      <Typography variant="h3" mb={3}>
        {title}
      </Typography>
      <Box className='cardDescription'>{description}</Box>
    </StyledFlex>
  )
}

export default HoverCard