import React from "react";
import { Icon } from "@iconify/react";
import { Grid, Typography } from "@mui/material";

interface Props {
  title: string;
  dataAos: string;
  description1: string;
  description2?: string;
  icon: any;
  bgColor: string;
  color: string;
  iconColor: string;
  pr?: any[];
  top?: any[];
  left: string[];
}
const Card = ({
  title,
  dataAos,
  description1,
  description2,
  icon,
  bgColor,
  color,
  iconColor,
  pr,
  top,
  left,
}: Props) => {
  return (
    <Grid
      data-aos={dataAos}
      flexDirection="column"
      p={4}
      maxWidth={["100%", "50%"]}
      flexGrow="1"
      position="relative"
      pr={pr}
      top={top}
      left={left}
      sx={{ bgcolor: bgColor }}
    >
      <Icon icon={icon} width="110px" color={iconColor} />
      <Typography color={color} variant="h3" pb={2}>
        {title}
      </Typography>
      <Typography variant="body1" color={color} pb={2}>
        {description1}
      </Typography>
      {description2 && (
        <Typography variant="body1" color={color}>
          {description2}
        </Typography>
      )}
    </Grid>
  );
};

export default Card;
