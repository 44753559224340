import * as React from "react";
import type { HeadFC } from "gatsby";

import { SEO } from "../components/SEO";
import { Hero } from "../screen/Home/Hero";
import Layout from "../components/Layout";
import { WhatMakeUsDifferent } from "../screen/Home/WhatMakeUsDifferent";
import WhoWeAre from "../screen/Home/WhoWeAre";
import BackgroundDivider from "../screen/Home/BackgroundDivider";
import OurValues from "../screen/Home/OurValues";
import OurServices from "../screen/Home/OurServices";
import CareerOpportunities from "../screen/Home/CareerOpportunities";

const IndexPage = () => {
  return (
    <Layout>
      <Hero
        title="Fibexty - ofrecemos las mejores soluciones"
        body="En Fibexty contamos siempre con líderes a nivel nacional e internacional. Nos centramos en la planificación integral a corto, medio y largo plazo, enfocada tanto al cliente particular como empresarial. La clave de nuestra actividad radica en el análisis y diseño de una estrategia basada en el análisis de la situación financiera y las coberturas de nuestros clientes."
      ></Hero>
      <WhatMakeUsDifferent />
      <WhoWeAre
        TitleSection="Quienes Somos"
        titleM="¿Qué hacemos?"
        mision="Nuestro compromiso es proteger tu salud financiera, ya sea a nivel particular o familiar, como a nivel empresarial."
        mision2="Cada uno de nuestros consultores están preparados para acompañarte durante todo la vida. Trabajamos para que en cada una de las fases más importantes de tu vida te podamos ayudar a cumplirlos económicamente, trabajando desde el inicio una planificación financiera, que te permita obtener un futuro financiero flexible."
        titleV="Equipo enfocado a la búsqueda de la mejor opción para el cliente"
        vision="Tenemos acuerdos con diferentes entidades financieras que nos permiten poder ofrecerte la mejor opción del mercado en cada momento, según tus necesidades y objetivos económicos. Estas colaboraciones con los diferentes partners se realizan con un estudio previo minucioso, para valorar cada unas de las opciones y alternativas, que cumplan los estándares y requisitos que la regulación nos exige de esta forma, protegemos al cliente ante cualquier anomalía que pueda suceder."
      />
      <BackgroundDivider />
      <OurValues />
      <OurServices
        TitleSection="Nuestros servicios"
        title="Futuro Financiero Flexible"
        body1="La 3F o triple F, es la esencia de nuestro servicio hacia los clientes, acompañado de las iniciales EDA"
        title1="¿Qué significa EDA?"
        body2="Estudio"
        body3="Se trata de una fase inicial en la que analizaremos el punto de partida, conociendo los productos y servicios ya contratados, además de indagar conociendo sus objetivos económicos en los diferentes horizontes temporales."
        body4="Desarrollo"
        body5="Basándonos en el punto anterior, tratar de mejorar lo que ya tuviera contratado y el cumplimiento de los objetivos definidos. De esta forma trataremos de conseguir en el futuro lo planteado en esa fase."
        body6="Acompañamiento"
        body7="Esta fase nos permite actualizar los objetivos marcados, además de balancear los activos financieros si fuera necesario para la consecución del objetivo."
        body8="La prueba de un buen servicio es que realizando este sistema desde el inicio, el exito esta asegurado."
      />
      <CareerOpportunities
          TitleSection="FIBEXTY: PLAN DE CRECIMIENTO"
          body1="Un Plan de Crecimiento, donde el primer dia conocerás las oportunidades que existen."
          body2="Destacamos por llevar a cabo y por lo tanto cumplirlo, los dos siguientes apartados."
          title="Plan de Mérito."
          body3=" La meritocracia se trata de uno de los sistemas que más éxito tienen en las compañías. Consiste en valorar a sus trabajadores de acuerdo al nivel de contribución, responsabilidades y exigencias que tiene cada puesto de trabajo. Y por ello, una vez conseguido los hitos marcados, esto te permitirá acceder a un mejor puesto de trabajo."
          title1="Plan de Valoración."
          body4="Se trata del plan que más satisface al equipo de trabajo. Consiste en tener en cuenta las aportaciones, el crecimiento, y la implicación que se tienen durante un determinado espacio de tiempo."
          body5="Esto significa que estos serán los dos únicos puntos que te permitirán crecer dentro de la compañía."
          body6="Además para conseguirlo, pondremos a tu alcance todas las herramientas para que puedas desarrollar las habilidades necesarias para tu crecimiento personal y profesional."
          body7="Somos un equipo de profesionales ayudando a cumplir tus metas"
        />
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <SEO title="Home"></SEO>;
