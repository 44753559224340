import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";

import BgImg from "../../../images/home-services.png";

interface Props {
  TitleSection: string;
  title: string;
  body1: string;
  title1: string;
  body2: string;
  body3: string;
  body4: string;
  body5: string;
  body6: string;
  body7: string;
  body8: string;
}

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.main,
  borderBottom: `1px solid ${theme.palette.custom.main}`,
  fontSize: "20px",
  paddingBottom: "8px",
  marginBottom: "24px",
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontWeight: "500",
}));

const Image = styled("img")(({ theme }) => ({
  overflow: "hidden",
  height: "350px",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "100%",
  },
}));

const OurServices = ({
  TitleSection,
  title,
  body1,
  title1,
  body2,
  body3,
  body4,
  body5,
  body6,
  body7,
  body8,
}: Props) => {
  return (
    <Box sx={{ bgcolor: "background.paper" }} py={4} id="services">
      <Box p={3}>
        <SectionTitle>{TitleSection}</SectionTitle>
        <Grid
          container
          flexDirection={[
            "column-reverse",
            "column-reverse",
            "column-reverse",
            "row",
          ]}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              data-aos="fade-down"
              position="relative"
              px={4}
              py={6}
              left={[0, 0, 0, 35]}
              top={[0, 0, 0, 35]}
              zIndex="2"
              width="100%"
              sx={{ bgcolor: "custom.main" }}
            >
              <Typography variant="h3" color="black" pb={3}>
                {title}
              </Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>{body1}</Typography>
              <Typography variant="h3" color="black" pb={3}>
                {title1}
              </Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>{body2}</Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>{body3}</Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>{body4}</Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>{body5}</Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>{body6}</Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>{body7}</Typography>
              <Typography variant="body1" sx={{ color: "custom.dark" }} pb={3}>{body8}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              position="relative"
              right={[0, 0, 0, 4]}
              width="100%"
              flexGrow="1"
            >
              <Image src={BgImg} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OurServices;
