import { Box, styled } from "@mui/material";
import React from "react";

import BgImage from "../../../images/home_background-divider.png";

const BackgroundDivider = () => (
  <Box
    sx={{
      backgroundImage: `url(${BgImage})`,
      backgroundAttachment: "fixed",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: "50vh",
    }}
  />
);

export default BackgroundDivider;
