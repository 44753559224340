import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import envelopeIcon from '@iconify/icons-uil/envelope'
import bullseyeIcon from '@iconify/icons-uil/bullseye'
import sitemapIcon from '@iconify/icons-uil/sitemap'
import suitcaseIcon from '@iconify/icons-uil/suitcase'
import tachometerFastAlt from '@iconify/icons-uil/tachometer-fast-alt'
import swatchbookIcon from '@iconify/icons-uil/swatchbook'

import HoverCard from "./HoverCard";

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.dark,
  borderBottom: `1px solid ${theme.palette.custom.dark}`,
  fontSize: "20px",
  paddingBottom: "8px",
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontWeight: "500",
}));

export const WhatMakeUsDifferent = () => {
  return (
    <>
      <Box id="trigger1">
        <Box p={3}>
          <SectionTitle my={3}>
            ¿Por qué las personas nos buscan?
          </SectionTitle>
          <Grid container flexWrap="wrap" justifyContent="stretch">
            <HoverCard
              icon={envelopeIcon}
              title="Misión"
              description="Tratar de ayudar a llevar unas finanzas saludables a cualquier cliente en cualquier lugar del país, con independencia de su capacidad económica, o situación particular."
            ></HoverCard>
            <HoverCard
              icon={bullseyeIcon}
              title="Plan de Crecimiento"
              description="Contamos con un excelente Plan de Crecimiento, que ayudará a los Agentes a poder ofrecer un servicio de calidad y cumpliendo los estándares que nos exigen nuestros reguladores."
            ></HoverCard>
            <HoverCard
              icon={sitemapIcon}
              title="Filosofía Corporativa"
              description="Trabajamos con el sistema EDA. 
                En primer lugar realizamos un ESTUDIO del cliente, donde analizaremos la situación económica actual, los productos financieros ya contratados y valor sus objetivos económicos para conseguir un futuro financiero flexible. Posteriormente pasaremos al DESARROLLO, donde le mostraremos las diferentes opciones del mercado para tratar de mejorar lo que ya tenga, o empezar a trabajar los objetivos económicos marcados. Por último trabajaremos de forma continuada en el tiempo, el ACOMPAÑAMIENTO, donde analizaremos cada cierto tiempo, la evolución de sus objetivos económicos."
            ></HoverCard>
            <HoverCard
            icon={suitcaseIcon}
              title="Partners"
              description="Contamos con colaboradores externos que nos permiten tener alcance a los diferentes opciones del mercado. Previamente realizaremos un estudio de mercado minucioso para analizar el partner y sus productos financieros, para así filtrar aquellas opciones que se amolden a nuestros clientes."
            ></HoverCard>
            <HoverCard
            icon={tachometerFastAlt}
              title="Visión"
              description="Desarrollar y potenciar una red de Agentes dedicados a la Intermediación Financiera, para ayudar a los clientes a lo largo de su vida. Trabajando con los mejores partners del mercado, y ofreciendo un gran abanico de opciones, para tratar de encontrar la mejor solución en cada momento."
            ></HoverCard>
            <HoverCard
            icon={swatchbookIcon}
              title="Herramientas"
              description="Somos especialistas en cuidar tu Salud FInanciera, y por ello nos apoyamos en herramientas que nos permiten dar soluciones a medida para cada cliente, en función de los objetivos económicos que se hayan marcado."
            ></HoverCard>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
