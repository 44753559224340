import { styled } from "@mui/material/styles";

import BgImg from '../../../images/team.png';
import { Box } from "@mui/material";

const HeroWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: `linear-gradient(
    to bottom,
    ${theme.palette.primary.main} 0%,
    ${theme.palette.primary.main} 20%,
    rgba(250, 250, 250, 0) 20%,
    url(${BgImg})
  )`,
  position:'absolute',
  width: '100vw',
  [theme.breakpoints.up('md')]: {
    backgroundImage: `url(${BgImg})`,
    backgroundSize: 'cover',
    minHeight: 'calc(100vh - 224px)',
    width: '80vw',
    right:'0',
    border: `32px solid ${theme.palette.primary.main}`,
    borderTop: 'none'
  },
}));

export default HeroWrapper
