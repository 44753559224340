import React from 'react'

import { Box, Grid, Typography } from '@mui/material'
import HeroWrapper from './HeroWrapper'
import { styled } from "@mui/material/styles";

interface PropsHero {
  title: string;
  body: string;
}

const HeroCont = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up('md')]: {
    minHeight: 'calc(100vh - 224px)'
  },
}));

const BoxLabels = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: 'white'
}))

const Hero = ({ title, body }:PropsHero) => (
  <HeroCont as="section">
    <HeroWrapper as="section" id="hero"></HeroWrapper>
    <Box minHeight="inherit" position='absolute' sx={{padding: '24px 24px 32px 24px'}}>
      <Grid
        container
        data-aos="fade-right"
        flexDirection="column"
        justifyContent={['start', 'center']}
        alignItems="start"
        minHeight={['80vh', 'inherit']}
      >
        <BoxLabels px={5} py={6} maxWidth="600px">
          <Typography mb={3} variant="h1">
            <strong> {title}</strong>
          </Typography>
          <Typography variant="body1">{body}</Typography>
        </BoxLabels>
      </Grid>
    </Box>
  </HeroCont>
)

export default Hero
