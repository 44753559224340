import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";

import usersIcon from "@iconify/icons-feather/users";
import compressArrows from "@iconify/icons-uil/arrow-growth";
import pathfinderUnite from "@iconify/icons-uil/pathfinder-unite";
import arrowGrowth from "@iconify/icons-uil/arrow-circle-up";
import commentsAlt from "@iconify/icons-uil/comments-alt";
import award from "@iconify/icons-uil/user-arrows";

import ValueCard from "./ValueCard";

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.custom.main,
  borderBottom: `1px solid ${theme.palette.custom.main}`,
  fontSize: "20px",
  paddingBottom: "8px",
  marginBottom: "24px",
  textTransform: "uppercase",
  letterSpacing: "3px",
  fontWeight: "500",
}));

const OurValues = () => {
  return (
    <Box sx={{ bgcolor: "background.paper" }} py={4}>
      <Box p={3}>
        <SectionTitle>
          FIBEXTY la compañía índice de referencia por el equipo profesional que
          lo componen.
        </SectionTitle>
        <Grid container flexWrap="wrap" justifyContent="center">
          <ValueCard
            title="Independencia"
            description="Es uno de nuestros lemas a la hora de trabajar con nuestros clientes. Nos permite ofrecer siempre la mejor alternativa."
            icon={usersIcon}
          />
          <ValueCard
            title="Crecimiento"
            description="Apostamos por una formación continua, y con oportunidades de ocupar diferentes posiciones dentro de la compañía."
            icon={compressArrows}
          />
          <ValueCard
            title="Innovación"
            description="Estamos comprometidos estar actualizados con el mercado y con una continua evolución de nuestro sistema, junto con las herramientas que usamos."
            icon={pathfinderUnite}
          />
          <ValueCard
            title="Alto Rendimiento"
            description="Preparados para que los equipos de Agentes tengan las competencias y capacidades para ofrecer el mejor servicio con la mayor excelencia. Esto nos permite tener un equipo profesional con ganas de superación constante."
            icon={arrowGrowth}
          />
          <ValueCard
            title="Honestidad"
            description="Se trata de una de nuestras señas de identidad, siendo transparentes ante los clientes y nuestros compañeros."
            icon={commentsAlt}
          />
          <ValueCard
            title="Acompañamiento"
            description="Está dentro de nuestro sistema EDA que usamos para el servicio al cliente, pero replicamos este servicio también con nuestro equipo de trabajo desde el inicio, para que se sientan integrados desde el primer dia."
            icon={award}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default OurValues;
